<template>
  <div>
    <van-nav-bar
      title="编辑档案"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-form @submit="onSubmit">
      <van-field
        v-model="regionValue"
        is-link
        readonly
        label="选择区域"
        placeholder="请选择所在地区"
        @click="regionShow = true"
        :rules="[{ required: true, message: '请先选择所在地区' }]"
      />
      <van-popup v-model="regionShow" round position="bottom">
        <van-cascader
          v-model="cascaderValue"
          title="请选择所在地区"
          :options="regionOptions"
          @close="regionShow = false"
          @change="onChange"
          @finish="onFinish"
          :field-names="fieldNames"
        />
      </van-popup>
      <!-- 选择地块 -->
      <van-field
        v-model="massifValue"
        is-link
        readonly
        label="选择地块"
        placeholder="请选择地块"
        @click="massifShow = true"
        :rules="[{ required: true, message: '请先选择地块' }]"
      />
      <van-popup v-model="massifShow" round position="bottom">
        <van-cascader
          v-model="cascadermassif"
          title="请选择地块"
          :options="massifOptions"
          @close="massifShow = false"
          @finish="massifFinish"
          @change="massifChange"
          :field-names="massifNames"
        />
      </van-popup>
      <!-- 选择作物 -->
      <van-field
        v-model="cropValue"
        is-link
        readonly
        label="选择作物"
        placeholder="请选择作物"
        @click="cropShow = true"
        :rules="[{ required: true, message: '请先选择作物' }]"
      />
      <van-popup v-model="cropShow" round position="bottom">
        <van-cascader
          v-model="cascaderCrop"
          title="请选择作物"
          :options="cropOptions"
          @close="cropShow = false"
          @finish="cropFinish"
          @change="cropChange"
          :field-names="massifNames"
        />
      </van-popup>
      <!-- 选择品种 -->
      <van-field
        v-model="varietiesValue"
        is-link
        readonly
        label="选择品种"
        placeholder="请选择品种"
        @click="varietiesShow = true"
        :rules="[{ required: true, message: '请先选择品种' }]"
      />
      <van-popup v-model="varietiesShow" round position="bottom">
        <van-cascader
          v-model="cascaderVarieties"
          title="请选择品种"
          :options="varietiesOptions"
          @close="varietiesShow = false"
          @finish="varietiesFinish"
          :field-names="massifNames"
        />
      </van-popup>
      <!-- 种植名称 -->
      <van-field
        v-model="name"
        name="用户名"
        label="种植名称"
        placeholder="请输入种植名称"
        :rules="[{ required: true, message: '请输入种植名称' }]"
      />
      <!-- 种植编码 -->
      <van-field
        v-model="plantingCode"
        name="种植编码"
        label="种植编码"
        :rules="[{ required: true, message: '种植编码不能为空' }]"
      />
      <!-- 种植数量 -->
      <van-field
        v-model="count"
        name="种植数量"
        label="种植数量"
        placeholder="请输入种植数量"
        :rules="[{ required: true, message: '请输入种植数量' }]"
      />
      <!-- 种植年限 -->
      <van-cell title="种植年限" :value="date" @click="show = true" />
      <van-calendar
        v-model="show"
        @confirm="onConfirm"
        :min-date="minDate"
        :max-date="maxDate"
      />
      <!-- 采收信息 -->
      <van-field
        v-model="info"
        :rows="2"
        autosize
        label="采收信息"
        type="textarea"
        placeholder="请输入采收颜色-采收尺寸-采收重量"
      />
      <!-- 种植照片 -->
      <van-field label="档案照片" />
      <van-uploader multiple="true" v-model="fileList" />
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >编辑档案</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import {
  NavBar,
  Popup,
  Field,
  Cascader,
  Form,
  Calendar,
  Cell,
  Uploader,
  Button,
  Dialog,
  Notify
} from "vant";
import {
  provinceList,
  cityList,
  countyList,
  getSelectListAll,
  getByLandIds,
  getLandsCropTypeList,
  getById,
//   CorpById,
//   CorpTypeById,
//   LandById,
  plantFileUpdate
} from "../../service/archives/archives";
export default {
  data() {
    return {
      // 选择省市县
      regionCode: "",
      regionValue: "",
      cascaderValue: "",
      regionShow: false,
      regionOptions: [],
      fieldNames: {
        text: "name",
        value: "code"
      },
      //   选择地块
      massifCode: "",
      massifValue: "",
      massifShow: false,
      cascadermassif: "",
      massifOptions: [],
      massifNames: {
        text: "name",
        value: "id"
      },
      //   选择作物
      cropCode: "",
      cropValue: "",
      cascaderCrop: "",
      cropId: "",
      cropShow: false,
      cropOptions: [],
      //   选择品种
      landIds: "",
      cropTypeId: "",
      varietiesValue: "",
      varietiesShow: false,
      cascaderVarieties: "",
      varietiesOptions: [],
      //   名称
      name: "",
      //   种植编码
      plantingCode: "",
      count: "",
      // 种植年限
      date: "",
      show: false,
      // 采收信息
      info: "",
      image: [],
      provinceCode: "",
      cityCode: "",
      countyCode: "",
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(),
      cropCascaderIndex: {
        a: "",
        b: "",
        c: ""
      },
      fileList: []
    };
  },
  components: {
    VanNavBar: NavBar,
    VanField: Field,
    VanCascader: Cascader,
    VanPopup: Popup,
    VanForm: Form,
    VanCalendar: Calendar,
    VanCell: Cell,
    VanUploader: Uploader,
    VanButton: Button,
    [Notify.Component.name]: Notify.Component
  },
  methods: {
    //   获取数据回填表单
    async init() {
      const id = this.$route.params && this.$route.params.id
        const res = await getById(id)
        this.image = res.data.imageUrl
        var isimage = true
        for (var i = 0; i < this.image.length; i++) {
          var url = this.image[i]
          this.fileList.push({ isimage, url })
        }
        this.provinceCode = res.data.province
        this.cityCode = res.data.city
        this.countyCode = res.data.county
        this.count = res.data.count
        this.cropCode = res.data.crop_id
        this.cropTypeId = res.data.crop_type_id
        this.date = res.data.date
        this.info = res.data.info
        this.name = res.data.name
        this.landIds = res.data.land_id
        this.cascaderValue = res.data.county
        this.cascadermassif = res.data.land_id
        this.cascaderCrop = res.data.crop_id
        this.cascaderVarieties = res.data.crop_type_id
        this.plantingCode =
        this.countyCode + "+" + this.landIds + "+" + this.cropCode;
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    // 处理接口数据
    handleCropData(data) {
      data.map((item) => {
        item.children = [];
      });
      return data;
    },
    // 选择省市县变化时
    async getProvince() {
      const res = provinceList();
      this.regionOptions = this.handleCropData((await res).data);
    },
    onChange({ value, tabIndex }) {
      if (tabIndex === 0) {
        cityList(value).then((res) => {
          if (res.code === 200) {
            this.provinceCode = value;
            const index = this.regionOptions.findIndex((item) => {
              return item.code === value;
            });
            this.cropCascaderIndex.a = index;
            this.regionOptions[index].children = this.handleCropData(res.data);
          }
        });
      } else if (tabIndex === 1) {
        countyList(value).then((res) => {
          if (res.code === 200) {
            this.cityCode = value;
            const index = this.regionOptions[
              this.cropCascaderIndex.a
            ].children.findIndex((item) => {
              return item.code === value;
            });
            this.cropCascaderIndex.b = index;
            this.regionOptions[this.cropCascaderIndex.a].children[
              index
            ].children = res.data;
          }
        });
      }
      this.massifValue = "";
      this.massifOptions = [];
      this.cropValue = "";
      this.cropOptions = [];
      this.varietiesValue = ""
      this.varietiesOptions = []
      this.plantingCode = ""
    },
    // 选择区域完成
    onFinish({ value, selectedOptions, tabIndex }) {
      if (tabIndex === 2) {
        this.countyCode = value;
        this.regionShow = false;
        this.regionValue = selectedOptions
          .map((option) => option.name)
          .join("/");
        this.regionCode = value;
      }
      getSelectListAll(value).then((res) => {
        this.massifOptions = res.data;
      });
    },
    massifChange() {
      this.cropValue = "";
      this.cropOptions = [];
      this.varietiesValue = ""
      this.varietiesOptions = []
      this.plantingCode = ""
    },
    // 选择地块完成
    massifFinish({ value, selectedOptions, tabIndex }) {
      this.massifShow = false;
      this.massifValue = selectedOptions.map((option) => option.name).join("/");
      this.landIds = value;
      getByLandIds(value).then((res) => {
        this.cropOptions = res.data;
      });
    },
    cropChange() {
      this.varietiesValue = ""
      this.varietiesOptions = []
      this.plantingCode = ""
    },
    // 选择作物完成后
    cropFinish({ value, selectedOptions, tabIndex }) {
      this.cropShow = false;
      this.cropValue = selectedOptions.map((option) => option.name).join("/");
      this.cropCode = value;
      getLandsCropTypeList(this.landIds, value).then((res) => {
        this.varietiesOptions = res.data;
      });
    },
    // 选择品种完成以后
    varietiesFinish({ value, selectedOptions, tabIndex }) {
      this.cropTypeId = value;
      this.varietiesShow = false;
      this.varietiesValue = selectedOptions
        .map((option) => option.name)
        .join("/");
      this.plantingCode =
        this.regionCode + "+" + this.landIds + "+" + this.cropCode;
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(value) {
      this.show = false;
      const date = new Date(value);
      const y = date.getFullYear();
      const m = date.getMonth() + 1;
      const d = date.getDate();
      const time = y + "-" + m + "-" + d;
      this.date = time;
    },
    onSubmit(values) {
      if (values === "") {
        Dialog.confirm({
          title: "提交圈舍表单失败",
          message: "请重新提交"
        }).catch(() => {
          // on cancel
        });
      } else {
        Dialog.confirm({
          title: "提交档案表单表单",
          message: "您确认要提交嘛"
        }).then(() => {
        const id = this.$route.params && this.$route.params.id
        const param = new FormData();
        for (var i = 0; i < this.fileList.length; i++) {
              param.append("image", this.fileList[i].file)
          };
          param.append("id", id)
          param.append("province", this.provinceCode);
          param.append("city", this.cityCode);
          param.append("county", this.countyCode);
          param.append("count", this.count);
          param.append("crop_id", this.cropCode);
          param.append("crop_type_id", this.cropTypeId);
          param.append("date", this.date);
          param.append("info", this.info);
          param.append("land_id", this.landIds);
          param.append("name", this.name);
          plantFileUpdate(param).then((res) => {
            if (res.code === 200) {
              Notify({ type: "success", message: "编辑成功" });
              this.$router.push("/mine/archives");
            } else {
              Notify({ type: "error", message: "编辑失败" });
            }
          });
        });
      }
    }
  },
  mounted() {
    this.getProvince();
    this.init()
  }
};
</script>

<style lang="sass" scoped>
</style>